import React, { useEffect, useState } from "react";
import AxiosInstance from "../../Requests/AxiosInstance";
import { Button, Divider, Form, Input, Spin } from "antd";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    [{ direction: "rtl" }], // this is rtl support
  ],
};

const handleFilterValue = (value) => {
  if (value === "<p><br></p>" || value.length === 0) {
    return "";
  }
  return value;
};

function Material() {
  const [data, setData] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    AxiosInstance.get(`text-settings`).then((res) => {
      setData(res.data.data);
    });
  }, []);

  const initialValues = {};
  data?.forEach((el) => {
    initialValues[`${el.key}_en`] = el.content_en;
    initialValues[`${el.key}_ar`] = el.content_ar;
  });

  const [loading, setLoading] = useState(false);

  const handleSubmit = (v) => {
    setLoading(true);

    const formData = new FormData();

    Object.keys(v).forEach((key) => {
      formData.append(key, v[key]);
    });
    formData.append("_method", "put");
    AxiosInstance.post(`text-settings`, formData)
      .then((res) => {
        toast.success(res.data.message);
        setLoading(false);
      })
      .catch((err) => {
        // toast.error(err.response.data.message);
      });
  };

  return data ? (
    <Form
      name="edit-product-form"
      onFinish={handleSubmit}
      initialValues={initialValues}
      layout="vertical"
    >
      {data.map((el) => {
        return (
          <div key={el.key}>
            <Divider children={t(el.key)} orientation={"left"} />
            <Form.Item
              label={t("content_en")}
              name={`${el.key}_en`}
              getValueFromEvent={handleFilterValue}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: t("required"),
                },
              ]}
            >
              <ReactQuill modules={modules} theme="snow" />
            </Form.Item>
            <Form.Item
              label={t("content_ar")}
              name={`${el.key}_ar`}
              getValueFromEvent={handleFilterValue}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: t("required"),
                },
              ]}
            >
              <ReactQuill modules={modules} theme="snow" />
            </Form.Item>
          </div>
        );
      })}

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {t("Edit")}
        </Button>
      </Form.Item>
    </Form>
  ) : (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin />
    </div>
  );
}

export default Material;
