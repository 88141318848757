import React, { useEffect, useState } from "react";
import { Form, Input, Switch, Button, Upload, Select, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { editPartner, fetchPartnerById } from "../../state/partnerSlice";
import { Option } from "antd/es/mentions";
import { FileDoneOutlined, UploadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const EditPartners = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { record: partnerItem } = useSelector((state) => state.partners);

  useEffect(() => {
    dispatch(fetchPartnerById(id));
  }, [dispatch, id]);

  const handleSubmit = async (values) => {
    console.log({ values });
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("_method", "PUT");
      formData.append("name_en", values.name_en);
      formData.append("name_ar", values.name_ar);
      if (values.logo && values.logo[0].originFileObj) {
        formData.append("logo", values.logo[0].originFileObj);
      }

      await dispatch(editPartner({ id: partnerItem.id, formData }));
      navigate("/Partners");
    } catch (error) {
      console.log("Error occurred while updating news: ", error);
    } finally {
      setLoading(false);
    }
  };

  // const handleRemoveImage = (file) => {
  //   const { id } = file;
  //   dispatch(deleteSingleImage(id));
  // };

  if (!partnerItem) {
    return <Spin className="spinner" />;
  }

  return (
    <Form
      name="edit-product-form"
      onFinish={handleSubmit}
      initialValues={partnerItem}
      layout="vertical"
    >
      <Form.Item
        label={t("name_en")}
        name="name_en"
        rules={[
          {
            required: true,
            whitespace: true,
            message: t("required"),
          },
          {
            validator: (_, value) => {
              if (
                value &&
                !/^[a-zA-Z0-9\s!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/.test(value)
              ) {
                return Promise.reject(
                  t("Please enter a valid input with English letters")
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("name_ar")}
        name="name_ar"
        rules={[
          {
            required: true,
            whitespace: true,
            message: t("required"),
          },
          {
            validator: (_, value) => {
              if (
                value &&
                !/^[\u0600-\u06FF0-9\s!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/.test(
                  value
                )
              ) {
                return Promise.reject(
                  t("Please enter a valid input with Arabic letters")
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t("link")}
        name="link"
        rules={[
          {
            required: true,
            whitespace: true,
            message: t("required"),
          },
          {
            type: "url",
            message: t("valid_url"),
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t("partner_images")}
        name="logo"
        valuePropName="fileList"
        rules={[{ required: true, message: t("req-logo") }]}
        getValueProps={function (url) {
          return {
            fileList:
              typeof url === "string"
                ? url
                  ? [
                      {
                        uid: "-1",
                        name: "advanced_cargo_identification",
                        status: "done",
                        url: url,
                      },
                    ]
                  : []
                : url,
          };
        }}
        getValueFromEvent={(e) => {
          if (Array.isArray(e)) {
            return e;
          }
          return e && e.fileList;
        }}
      >
        <Upload
          maxCount={1}
          name="logo"
          beforeUpload={(file) => {
            return false;
          }}
          listType="picture-card"
        >
          <UploadOutlined />
        </Upload>
      </Form.Item>

      {/* <Form.Item
        label={t("partner_images")}
        name="logo"
        valuePropName="logo"
        getValueFromEvent={(e) => e.fileList}
        rules={[{ required: true, message: "Please upload product images!" }]}
      >
        <Upload
          multiple
          beforeUpload={() => false}
          listType="picture-card"
          accept=".jpg, .jpeg, .png"
          // fileList={partnerItem?.logo}
          // onRemove={(file) => handleRemoveImage(file)}
        >
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>{t("Upload")}</div>
          </div>
        </Upload>
      </Form.Item> */}

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          style={{ display: "flex" }}
        >
          {t("EditProduct")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EditPartners;
