import { lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Partners from "../pages/Partners/Partners";
import AddPartners from "../pages/Partners/AddPartners";
import EditPartners from "../pages/Partners/EditPartners";
import Material from "../pages/material/Material";
const SignIn = lazy(() => import("../pages/SignIn"));
const Main = lazy(() => import("../components/layout/Main"));
const Home = lazy(() => import("../pages/Home"));
const Category = lazy(() => import("../pages/Category/Category"));
const AddCategory = lazy(() => import("../pages/Category/AddCategory"));
const EditCategory = lazy(() => import("../pages/Category/EditCategory"));
const Product = lazy(() => import("../pages/Product/Product"));
const AddProduct = lazy(() => import("../pages/Product/AddProduct"));
const EditProduct = lazy(() => import("../pages/Product/EditProduct"));
const Catalog = lazy(() => import("../pages/Catalog/Catalog"));
const AddCatalog = lazy(() => import("../pages/Catalog/AddCatalog"));
const EditCatalog = lazy(() => import("../pages/Catalog/EdditCatalog"));
const JoinUs = lazy(() => import("../pages/JoinUs/JoinUs"));
const Requests = lazy(() => import("../pages/Requests/Requests"));
const RequestDetail = lazy(() => import("../pages/Requests/RequestDetail"));
const Error404 = lazy(() => import("../pages/Error404"));

const MasterRoutes = () => {
  const AuthData = !!localStorage.TOKEN;

  return useRoutes([
    {
      path: "/",
      element: AuthData ? <Main /> : <Navigate to={"login"} />,
      children: [
        { index: true, element: <Home /> },
        {
          path: "dashboard",
          element: <Home />,
        },
        {
          path: "Category",
          element: <Category />,
        },
        {
          path: "Category/add",
          element: <AddCategory />,
        },
        {
          path: "Category/:id",
          element: <EditCategory />,
        },
        {
          path: "Product",
          element: <Product />,
        },
        {
          path: "Product/add",
          element: <AddProduct />,
        },
        {
          path: "Product/:id",
          element: <EditProduct />,
        },
        {
          path: "Catalog",
          element: <Catalog />,
        },
        {
          path: "Catalog/add",
          element: <AddCatalog />,
        },
        {
          path: "Catalog/:id",
          element: <EditCatalog />,
        },
        {
          path: "JoinUs",
          element: <JoinUs />,
        },
        {
          path: "Requests",
          element: <Requests />,
        },
        {
          path: "Requests/:id",
          element: <RequestDetail />,
        },
        {
          path: "partners",
          element: <Partners />,
        },
        {
          path: "partners/add",
          element: <AddPartners />,
        },
        {
          path: "partners/:id",
          element: <EditPartners />,
        },
        {
          path: "text_settings",
          element: <Material />,
        },
      ],
    },
    {
      path: "login",
      element: AuthData ? <Navigate to={"/"} /> : <SignIn />,
    },
    { path: "*", element: <Error404 /> },
  ]);
};

export default MasterRoutes;
