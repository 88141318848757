import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import AxiosInstance from '../Requests/AxiosInstance';
// React Toastify
import { toast } from 'react-toastify';

const initialState = { records: [], loading: false, error: null }

export const fetchCategory = createAsyncThunk(
  "categories/fetchCategories",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = AxiosInstance.get("categories").then((res) => {
        return res.data
      })
      return result
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchCategoryById = createAsyncThunk(
  "category/fetchCategoryById",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await AxiosInstance.get(`categories/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addCategory = createAsyncThunk(
  "category/category",
  async (category, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await AxiosInstance.post("categories", category);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const editCategory = createAsyncThunk(
  "category/editCategory",
  async ({ id, formData }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await AxiosInstance.post(`categories/${id}`, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "category/deleteCategory",
  async (categoryId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await AxiosInstance.delete(`categories/${categoryId}`);
      return { categoryId, message: response.data.message };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


const Categorys = createSlice({
  name: 'post',
  initialState,
  reducers: {},
  extraReducers: {
    // fetch data
    [fetchCategory.pending]: (state) => { state.loading = true; },

    [fetchCategory.fulfilled]: (state, action) => {
      state.loading = false;
      // state.records.push(...action.payload.data);
      state.records = action.payload.data;
    },
    [fetchCategory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload
    },

    //fetchCategoryById
    [fetchCategoryById.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.record = null;
    },
    [fetchCategoryById.fulfilled]: (state, action) => {
      state.loading = false;
      state.record = action.payload.data;
    },
    [fetchCategoryById.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // Add Category
    [addCategory.pending]: (state) => {
      state.loading = true;
    },
    [addCategory.fulfilled]: (state, action) => {
      state.loading = false;
      state.records.push(action.payload);
      toast.success(action.payload.message, {
        position: toast.POSITION.TOP_RIGHT,
        toastId: 'addCategory',
      });
    },
    [addCategory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    //Edit Category
    [editCategory.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [editCategory.fulfilled]: (state, action) => {
      state.loading = false;
      state.record = action.payload;
      toast.success(action.payload.message, {
        position: toast.POSITION.TOP_RIGHT,
        toastId: 'editCategory',
      });
    },
    [editCategory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // Delete Category
    [deleteCategory.pending]: (state) => {
      state.loading = true;
    },
    [deleteCategory.fulfilled]: (state, action) => {
      state.loading = false;
      state.records = state.records.filter(post => post.id !== action.payload.categoryId);
      toast.success(action.payload.message, {
        position: toast.POSITION.TOP_RIGHT,
        toastId: 'delete',
      });
    },
    [deleteCategory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  }
})

export default Categorys.reducer
