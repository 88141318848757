import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../Requests/AxiosInstance";
// React Toastify
import { toast } from "react-toastify";

const initialState = { records: [], loading: false, error: null };

export const fetchProduct = createAsyncThunk(
  "products/fetchproducts",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = AxiosInstance.get("products").then((res) => {
        return res.data;
      });
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchProductById = createAsyncThunk(
  "products/fetchProductById",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await AxiosInstance.get(`products/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addProduct = createAsyncThunk(
  "products/Product",
  async (Product, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await AxiosInstance.post("products", Product);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const editProduct = createAsyncThunk(
  "products/editproduct",
  async ({ id, formData }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await AxiosInstance.post(`products/${id}`, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "products/deleteProduct",
  async (productId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await AxiosInstance.delete(`products/${productId}`);
      return { productId, message: response.data.message };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const deleteSingleImage = createAsyncThunk(
  "products/deleteFile",
  async (imageId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await AxiosInstance.delete(`delete-file/${imageId}`);
      return { message: response.data.message };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const productSLice = createSlice({
  name: "post",
  initialState,
  reducers: {},
  extraReducers: {
    // fetch data
    [fetchProduct.pending]: (state) => {
      state.loading = true;
    },

    [fetchProduct.fulfilled]: (state, action) => {
      state.loading = false;
      // state.records.push(...action.payload.data);
      state.records = action.payload.data;
    },
    [fetchProduct.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    //fetchProductById
    [fetchProductById.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.record = null;
    },
    [fetchProductById.fulfilled]: (state, action) => {
      state.loading = false;
      state.record = action.payload.data;
    },
    [fetchProductById.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // Add Product
    [addProduct.pending]: (state) => {
      state.loading = true;
    },
    [addProduct.fulfilled]: (state, action) => {
      state.loading = false;
      state.records.push(action.payload);
      toast.success(action.payload.message, {
        position: toast.POSITION.TOP_RIGHT,
        toastId: "addProduct",
      });
    },
    [addProduct.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    //Edit Product
    [editProduct.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [editProduct.fulfilled]: (state, action) => {
      state.loading = false;
      state.record = action.payload;
      toast.success(action.payload.message, {
        position: toast.POSITION.TOP_RIGHT,
        toastId: "editCategory",
      });
    },
    [editProduct.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // Delete Product
    [deleteProduct.pending]: (state) => {
      state.loading = true;
    },
    [deleteProduct.fulfilled]: (state, action) => {
      state.loading = false;
      state.records = state.records.filter(
        (post) => post.id !== action.payload.productId
      );
      toast.success(action.payload.message, {
        position: toast.POSITION.TOP_RIGHT,
        toastId: "delete",
      });
    },
    [deleteProduct.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // Delete single Image
    [deleteSingleImage.pending]: (state) => {
      state.loading = true;
    },
    [deleteSingleImage.fulfilled]: (state, action) => {
      state.loading = false;
      toast.success(action.payload.message, {
        position: toast.POSITION.TOP_RIGHT,
        toastId: "delete",
      });
    },
    [deleteSingleImage.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default productSLice.reducer;
