import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import AxiosInstance from './../Requests/AxiosInstance';
// React Toastify
import { toast } from 'react-toastify';

const initialState = { loading: false, error: null, token: null }

export const SIgnIn = createAsyncThunk(
    "login",
    async ({ formData }, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            const response = await AxiosInstance.post(`login`, formData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);
export const SIgnOut = createAsyncThunk(
    "logout",
    async (_, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            const response = await AxiosInstance.post(`logout`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: {
        [SIgnIn.pending]: (state) => {
            state.loading = true;
        },
        [SIgnIn.fulfilled]: (state, action) => {
            state.loading = false;
            state.token = action.payload
            const { token } = action.payload;
            localStorage.setItem('TOKEN', token);
            toast.success(action.payload.message, {
                position: toast.POSITION.TOP_RIGHT,
                toastId: 'logIn',
            });
        },
        [SIgnIn.rejected]: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
        // handle log out
        [SIgnOut.pending]: (state) => {
            state.loading = true;
        },
        [SIgnOut.fulfilled]: (state, action) => {
            state.loading = false;
            state.token = null;
            localStorage.removeItem('TOKEN');
            toast.success(action.payload.message, {
                position: toast.POSITION.TOP_RIGHT,
                toastId: 'logOut',
            });
        },
        [SIgnOut.rejected]: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
    },
});


export default authSlice.reducer
