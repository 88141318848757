import React, { useEffect } from "react";
import { Table, Button, Tooltip, Popconfirm } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchPartner, deletePartner } from "../../state/partnerSlice";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Partners = () => {
  const { t } = useTranslation();
  const [start, setStart] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { records, loading } = useSelector((state) => state.partners);
  useEffect(() => {
    dispatch(fetchPartner());
  }, [dispatch]);

  const columns = [
    {
      title: t("Index"),
      key: "id",
      render: (text, object, index) => index + 1 + start,
    },
    {
      title: t("name_en"),
      dataIndex: "name_en",
      key: "name_en",
      render: (name_en) => (
        <Tooltip title={name_en}>
          <div
            style={{
              maxWidth: "200px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {name_en}
          </div>
        </Tooltip>
      ),
    },
    {
      title: t("name_ar"),
      dataIndex: "name_ar",
      key: "name_ar",
      render: (name_ar) => (
        <Tooltip title={name_ar}>
          <div
            style={{
              maxWidth: "200px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {name_ar}
          </div>
        </Tooltip>
      ),
    },

    {
      title: t("created_at"),
      dataIndex: "created_at",
      key: "created_at",
      render: (date) =>
        new Date(date).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        }),
    },
    {
      title: t("partner_images"),
      dataIndex: "logo",
      key: "logo",
      render: (image) => (
        <div
          className="product_images_cobtainer"
          style={{ display: "flex", gap: "8px" }}
        >
          <img
            src={image}
            alt={`Partner Image`}
            style={{
              maxWidth: "100px",
              maxHeight: "100px",
              objectFit: "cover",
            }}
          />
        </div>
      ),
    },

    {
      title: t("actions"),
      dataIndex: "actions",
      render: (text, record) => (
        <div style={{ display: "flex", gap: "7px", alignItems: "center" }}>
          <Button type="primary" onClick={() => navigate(`${record.id}`)}>
            {t("Edit")}
          </Button>
          <Popconfirm
            title={t("confirmDelete")}
            onConfirm={() => dispatch(deletePartner(record.id))}
            okText={t("Yes")}
            cancelText={t("No")}
            okButtonProps={{ style: { height: "40px", width: "44px" } }}
            cancelButtonProps={{ style: { height: "40px", width: "44px" } }}
          >
            <Button type="primary" danger>
              {t("Delete")}
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const onTableChange = (pagination) => {
    const pager = { ...pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    setStart((pager.current - 1) * 10);
  };

  return (
    <section className="products">
      <div className="add_wrabber">
        <Button className="add" type="primary" onClick={() => navigate("add")}>
          {t("Add")}
        </Button>
      </div>
      <div
        className="table_wrabber"
        style={{ width: "100%", overflowX: "scroll" }}
      >
        <Table
          onChange={onTableChange}
          columns={columns}
          dataSource={records}
          loading={loading}
          pagination={{
            pageSizeOptions: ["10", "20", "50", "100"],
            showSizeChanger: records.length > 10,
            defaultPageSize: 10,
          }}
          rowKey="key"
        />
      </div>
    </section>
  );
};
export default Partners;
