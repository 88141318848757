import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import AxiosInstance from '../Requests/AxiosInstance';

const initialState = { records: {}, loading: false, error: null }

export const fetchStatistics = createAsyncThunk(
    "statistics/fetchStatistics",
    async (_, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const result = AxiosInstance.get("statistics").then((res) => {
          return res.data
        })
        return result
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );


const statistics = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchStatistics.pending]: (state) => { state.loading = true; },

        [fetchStatistics.fulfilled]: (state, action) => {
          state.loading = false;
          state.records = action.payload.data;
        },
        [fetchStatistics.rejected]: (state, action) => {
          state.loading = false;
          state.error = action.payload
        },
    },
});


export default statistics.reducer
