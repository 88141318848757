import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../Requests/AxiosInstance";
// React Toastify
import { toast } from "react-toastify";

const initialState = { records: [], loading: false, error: null };

export const fetchMaterial = createAsyncThunk(
  "material/fetchMaterial",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = AxiosInstance.get("text-settings").then((res) => {
        return res.data;
      });
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const materialSLice = createSlice({
  name: "post",
  initialState,
  reducers: {},
  extraReducers: {
    // fetch data
    [fetchMaterial.pending]: (state) => {
      state.loading = true;
    },
    [fetchMaterial.fulfilled]: (state, action) => {
      state.loading = false;
      // state.records.push(...action.payload.data);
      state.records = action.payload.data;
    },
    [fetchMaterial.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default materialSLice.reducer;
