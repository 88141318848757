import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import AxiosInstance from '../Requests/AxiosInstance';
// React Toastify
import { toast } from 'react-toastify';

const initialState = { records: [], loading: false, error: null }

export const fetchCatalog = createAsyncThunk(
  "Catalog/fetchCatalog",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = AxiosInstance.get("catalogs").then((res) => {
        return res.data
      })
      return result
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchCatalogById = createAsyncThunk(
  "catalogs/fetchCatalogById",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await AxiosInstance.get(`catalogs/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addCatalog = createAsyncThunk(
  "catalogs/Catalog",
  async (Catalog, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await AxiosInstance.post("catalogs", Catalog);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const editCatalog = createAsyncThunk(
  "catalogs/editcatalog",
  async ({ id, formData }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await AxiosInstance.post(`catalogs/${id}`, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteCatalog = createAsyncThunk(
  "catalogs/deleteCatalog",
  async (catalogId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await AxiosInstance.delete(`catalogs/${catalogId}`);
      return { catalogId, message: response.data.message };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {},
  extraReducers: {
    // fetch data
    [fetchCatalog.pending]: (state) => { state.loading = true; },

    [fetchCatalog.fulfilled]: (state, action) => {
      state.loading = false;
      // state.records.push(...action.payload.data);
      state.records = action.payload.data;
    },
    [fetchCatalog.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload
    },

    //fetchCatalogById
    [fetchCatalogById.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.record = null;
    },
    [fetchCatalogById.fulfilled]: (state, action) => {
      state.loading = false;
      state.record = action.payload.data;
    },
    [fetchCatalogById.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // Add Catalog
    [addCatalog.pending]: (state) => {
      state.loading = true;
    },
    [addCatalog.fulfilled]: (state, action) => {
      state.loading = false;
      state.records.push(action.payload);
      toast.success(action.payload.message, {
        position: toast.POSITION.TOP_RIGHT,
        toastId: 'addCatalog',
      });
    },
    [addCatalog.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    //Edit Catalog
    [editCatalog.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [editCatalog.fulfilled]: (state, action) => {
      state.loading = false;
      state.record = action.payload;
      toast.success(action.payload.message, {
        position: toast.POSITION.TOP_RIGHT,
        toastId: 'editCatalog',
      });
    },
    [editCatalog.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // Delete Catalog
    [deleteCatalog.pending]: (state) => {
      state.loading = true;
    },
    [deleteCatalog.fulfilled]: (state, action) => {
      console.log(action.payload)
      state.loading = false;
      state.records = state.records.filter(catalog => catalog.id !== action.payload.catalogId);
      toast.success(action.payload.message, {
        position: toast.POSITION.TOP_RIGHT,
        toastId: 'delete',
      });
    },
    [deleteCatalog.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  }
})

export default catalogSlice.reducer
