import React, { Suspense } from 'react'
import MasterRoutes from './Routes/MasterRoutes';
import { Spin } from 'antd';

// styles
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
// import "antd/dist/antd.css";
import './App.css'
import RequestsProvider from './Requests/RequestsProvider';
import { BrowserRouter } from 'react-router-dom';

const App = () => {
  return (
        <BrowserRouter>
        <RequestsProvider>
          <Suspense fallback={<Spin className="spinner" />}>
            <MasterRoutes />
          </Suspense>
        </RequestsProvider>
      </BrowserRouter>
  )
}

export default App