import { configureStore } from "@reduxjs/toolkit";
import Categorys from "./CategorySlice";
import AuthSlice from "./AuthSlice";
import productSlice from "./ProductSlice";
import catalogSlice from "./CatalogSlice";
import Statistics from "./StatisticsSlice";
import JoinUs from "./JoinUsSlice";
import RequestsSlice from "./RequestsSlice";
import partnerSlice from "./partnerSlice";
import materialSlice from "./materialSlice";

const store = configureStore({
  reducer: {
    Statistics,
    JoinUs,
    requests: RequestsSlice,
    category: Categorys,
    Auth: AuthSlice,
    product: productSlice,
    catalog: catalogSlice,
    partners: partnerSlice,
    material: materialSlice,
  },
});
export default store;
