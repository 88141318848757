import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../Requests/AxiosInstance";
// React Toastify
import { toast } from "react-toastify";

const initialState = { records: [], loading: false, error: null };

export const fetchPartner = createAsyncThunk(
  "partners/fetchPartner",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = AxiosInstance.get("partners").then((res) => {
        return res.data;
      });
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchPartnerById = createAsyncThunk(
  "partners/fetchPartnerById",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await AxiosInstance.get(`partners/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addPartner = createAsyncThunk(
  "partners/partner",
  async (Product, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await AxiosInstance.post("partners", Product);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const editPartner = createAsyncThunk(
  "partners/editPartner",
  async ({ id, formData }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await AxiosInstance.post(`partners/${id}`, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deletePartner = createAsyncThunk(
  "partners/deletePartner",
  async (productId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await AxiosInstance.delete(`partners/${productId}`);
      return { productId, message: response.data.message };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const productSLice = createSlice({
  name: "post",
  initialState,
  reducers: {},
  extraReducers: {
    // fetch data
    [fetchPartner.pending]: (state) => {
      state.loading = true;
    },

    [fetchPartner.fulfilled]: (state, action) => {
      state.loading = false;
      // state.records.push(...action.payload.data);
      state.records = action.payload.data;
    },
    [fetchPartner.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    //fetchPartnerById
    [fetchPartnerById.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.record = null;
    },
    [fetchPartnerById.fulfilled]: (state, action) => {
      state.loading = false;
      state.record = action.payload.data;
    },
    [fetchPartnerById.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // Add Product
    [addPartner.pending]: (state) => {
      state.loading = true;
    },
    [addPartner.fulfilled]: (state, action) => {
      state.loading = false;
      state.records.push(action.payload);
      toast.success(action.payload.message, {
        position: toast.POSITION.TOP_RIGHT,
        toastId: "addPartner",
      });
    },
    [addPartner.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    //Edit Product
    [editPartner.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [editPartner.fulfilled]: (state, action) => {
      state.loading = false;
      state.record = action.payload;
      toast.success(action.payload.message, {
        position: toast.POSITION.TOP_RIGHT,
        toastId: "editPartner",
      });
    },
    [editPartner.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // Delete Product
    [deletePartner.pending]: (state) => {
      state.loading = true;
    },
    [deletePartner.fulfilled]: (state, action) => {
      state.loading = false;
      state.records = state.records.filter(
        (post) => post.id !== action.payload.productId
      );
      toast.success(action.payload.message, {
        position: toast.POSITION.TOP_RIGHT,
        toastId: "delete",
      });
    },
    [deletePartner.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default productSLice.reducer;
