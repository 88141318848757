import React from 'react';

// React Router
import AxiosInstance from './AxiosInstance';
import { useNavigate } from 'react-router-dom';

// React Toastify
import { toast } from 'react-toastify';

// Axios Instance

const RequestsProvider = ({ children }) => {
    const navigate = useNavigate();

    AxiosInstance.interceptors.request.use((config) => {
        if (config.url !== ('/login')) {
            const token = localStorage.getItem('TOKEN');
            const LANGUAGE = localStorage.getItem('LANGUAGE');
            config.headers.Authorization = token ? `Bearer ${token}` : '';
            config.headers['X-Language'] = LANGUAGE ? LANGUAGE : '';
        }
        return config;
    },
        (Error) => {
            return Promise.reject(Error);
        }
    );

    AxiosInstance.interceptors.response.use((Response) => { return Response },
        async (Error) => {

            if (Error.response) {
                // Check if the Access TOKEN is Expired
                if (Error.response.status === 401) {
                    toast.error(Error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                        toastId: 'uniqueId',
                    });
                    navigate('/login')
                }
                if (Error.response.status === 404) {
                    toast.error(Error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                        toastId: 'uniqueId',
                    });
                    navigate('404')
                }

                toast.error(Error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: 'uniqueId',
                });
            }
            return Promise.reject(Error);
        }
    );

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    )
};

export default RequestsProvider;