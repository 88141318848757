import {
  Form,
  Input,
  Switch,
  Select,
  Button,
  Spin,
  Upload,
  message,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addPartner } from "../../state/partnerSlice";
import { fetchCategory } from "../../state/CategorySlice";
import { useTranslation } from "react-i18next";

const AddPartners = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCategory());
  }, [dispatch]);

  const onFinish = async (values) => {
    console.log({ values });
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("name_en", values.name_en);
      formData.append("name_ar", values.name_ar);
      formData.append("link", values.link);

      formData.append("logo", values.logo[0].originFileObj);

      await dispatch(addPartner(formData));
      form.resetFields();
    } catch (error) {
      console.log("Error occurred while adding product: ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <Form
        name="add_product_form"
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          label={t("name_en")}
          name="name_en"
          rules={[
            {
              required: true,
              whitespace: true,
              message: t("required"),
            },
            {
              validator: (_, value) => {
                if (
                  value &&
                  !/^[a-zA-Z0-9\s!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/.test(
                    value
                  )
                ) {
                  return Promise.reject(
                    t("Please enter a valid input with English letters")
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("name_ar")}
          name="name_ar"
          rules={[
            {
              required: true,
              whitespace: true,
              message: t("required"),
            },
            {
              validator: (_, value) => {
                if (
                  value &&
                  !/^[\u0600-\u06FF0-9\s!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/.test(
                    value
                  )
                ) {
                  return Promise.reject(
                    t("Please enter a valid input with Arabic letters")
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("link")}
          name="link"
          rules={[
            {
              required: true,
              whitespace: true,
              message: t("required"),
            },
            {
              type: "url",
              message: t("valid_url"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("partner_images")}
          name="logo"
          valuePropName="fileList"
          getValueFromEvent={(e) => e && e.fileList}
          rules={[{ required: true, message: t("req-logo") }]}
        >
          <Upload.Dragger
            maxCount={1}
            name="product_images"
            accept=".jpg, .jpeg, .png"
            beforeUpload={() => false}
            onRemove={(file) => {
              const fileList = form.getFieldValue("product_images") || [];
              const index = fileList.indexOf(file);
              const newFileList = fileList.slice();
              newFileList.splice(index, 1);
              form.setFieldsValue({ product_images: newFileList });
              if (newFileList.length === 0) {
                form.validateFields(["product_images"]);
              }
            }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">{t("uploadProducts")}</p>
            <p className="ant-upload-hint">{t("supportImages")}</p>
          </Upload.Dragger>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ display: "flex" }}>
            {t("AddProduct")}
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default AddPartners;
