import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import AxiosInstance from './../Requests/AxiosInstance';

const initialState = { records: [],record:{}, loading: false, error: null }

export const fetchRequests = createAsyncThunk(
  "Requests/fetchRequests",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = AxiosInstance.get("requests").then((res) => {
        return res.data
      })
      return result
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchRequeststById = createAsyncThunk(
  "Requeststs/fetchRequeststById",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await AxiosInstance.get(`requests/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const requests = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchRequests.pending]: (state) => { state.loading = true; },

    [fetchRequests.fulfilled]: (state, action) => {
      state.loading = false;
      state.records = action.payload.data;
    },
    [fetchRequests.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload
    },

    //fetchProductById
    [fetchRequeststById.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [fetchRequeststById.fulfilled]: (state, action) => {
      state.loading = false;
      state.record = action.payload.data;
    },
    [fetchRequeststById.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

  },
});


export default requests.reducer
